import { CarouselBlock } from 'src/components/blocks/carousel/carousel'
import { GridBlock } from 'src/graphql/generated/graphql-types'

type Props = {
  blocks: GridBlock[]
}

export const Ads = ({ blocks }: Props) => {
  return (
    <div className="u-bg-white u-overflow-hidden">
      <div className="container">
        <div className="row d-block u-pt-24 u-pt-md-32 u-pb-24 u-pb-lg-40">
          <CarouselBlock name="adsblock" blocks={blocks} type="multi" disableSlider={false} />
        </div>
      </div>
    </div>
  )
}
